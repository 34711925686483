<template>

    <v-container fluid>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
        :items="pathModul"
        divider="-"
        normal
        class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6 mb-3' : 'px-1'" style="padding-top: 0px !important;padding-bottom: 0px !important;"> 

            <v-row align="center">
 
                <!-- Column Select Sampling Type -->
                <v-col class="col-input-filter" v-if="currentMode.a == true && currentMode.m == true">
                    <v-select
                    :items="listMode"
                    v-model="modeSelected"
                    label="Sampling Type"
                    solo 
                    dense
                    hide-details
                    @change="getListStation(modeSelected)"
                    ></v-select>
                </v-col>
 
                <!-- Column Select Station -->
                <v-col class="col-input-filter">
                    <v-autocomplete
                    v-model="selectedStation"
                    :items="listStations"
                    label="Station"
                    solo dense
                    hide-details
                    prepend-inner-icon="mdi-store-marker"
                    ></v-autocomplete>
                </v-col>

                <!-- Column Select Calendar From-->
                <v-col class="col-input-filter">
                    <v-menu
                    v-model="modelCalendarFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="selectedDateFrom"
                        prefix="From :"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        solo dense
                        hide-details
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFrom"
                        @input="modelCalendarFrom = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Select Calendar To-->
                <v-col class="col-input-filter">
                    <v-menu
                    v-model="modelCalendarTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="selectedDateTo"
                        prefix="To :"
                        prepend-inner-icon="mdi-calendar-end"
                        readonly solo dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateTo"
                        @input="modelCalendarTo = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- <v-col>
                    <v-text-field @input="test">

                    </v-text-field>
                </v-col> -->

                <!-- Column Generate Button -->
                <v-col>
                    <v-btn
                    id="generateReport"
                    class="white--text btn-generate button"
                    solo
                    :disabled="selectedStation == ''"
                    :loading="loadingTable_report"
                    @click="generateAllTable()"
                    >
                      GENERATE REPORT
                    </v-btn>
                </v-col>

            </v-row>

        </v-container>

        <!-- Section Result Generated Report (TABLE) -->
        <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'container-result-generated form-datatable px-6' : 'container-result-generated form-datatable px-1'" style="padding-top: 0px !important;padding-bottom: 0px !important;">

            <!-- Main Report Title/Tab -->

            <v-tabs
            v-model="modelTab"
            background-color="primary"
            >
                <v-tab v-for="item in tabs" :key="item.tab" class="white--text">
                    {{ item.tab }}
                </v-tab>
                <v-tabs-slider color="button"></v-tabs-slider>
            </v-tabs>

            <!-- Mini Title (Table Title) -->
    
            <v-tabs-items v-model="modelTab">

                <!-- Content Tab Validation Report -->
                <v-tab-item :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

                    <!-- Title -->
                    <v-row v-show="tbody_report.length != 0">
                        <v-col class="pb-0 mt-5">
                            <v-subheader 
                            class="d-flex justify-center align-center black--text font-weight-bold" 
                            style="text-transform:uppercase"
                            >
                                {{titleReport}}
                            </v-subheader>
                        </v-col>
                    </v-row>
                    
                    <!-- Main Report -->
                    <v-row v-show="view_report == 'main'">
                        <v-col>
                            
                            <v-row>
                                <v-col class="pa-1 pt-0">

                                    <v-data-table
                                    :headers="thead_report"
                                    :items="tbody_report"
                                    :header-props="{ sortIcon: null }"
                                    class="elevation-1 headerDtSarawak headerValidation"
                                    :class="{'datatable-mobile' : !$vuetify.breakpoint.lgAndUp}"
                                    :items-per-page="displayPerPage_report"
                                    no-select-on-click
                                    fixed-header
                                    :mobile-breakpoint="0"
                                    :loading="loadingTable_report"
                                    loading-text="Data Loading... Please wait"
                                    >
                                    
                                        <template
                                        v-slot:body="{ items }"
                                        >
                                        <tbody>

                                            <div
                                            v-if="items.length == 0 && thead_report.length != 0"
                                            class="pa-2 text-body-2"
                                            style="white-space: nowrap;"
                                            >
                                                No Data Available
                                            </div>

                                            <tr
                                            v-for="(item,x) in items"
                                            :key="x"
                                            >
                                                <td 
                                                v-for="(itemtd,i) in thead_report" 
                                                :key="i" 
                                                :class="item[itemtd.value.replace('TEMP','TEMPERATURE').replace('DOCON','DO_CON').replace('DOSAT','DO_SAT')+'_selected'] == true? 'td-selected text-center white--text' : setClassTDReport(item[itemtd.value+'_FLAG'],item[itemtd.value.replace('TEMP','TEMPERATURE').replace('DOCON','DO_CON').replace('DOSAT','DO_SAT')],itemtd.value.replace('TEMP','TEMPERATURE').replace('DOCON','DO_CON').replace('DOSAT','DO_SAT'),item) "
                                                @click="clickOnTDTableReport(item[itemtd.value+'_FLAG'],item[itemtd.value.replace('TEMP','TEMPERATURE').replace('DOCON','DO_CON').replace('DOSAT','DO_SAT')],itemtd.value.replace('TEMP','TEMPERATURE').replace('DOCON','DO_CON').replace('DOSAT','DO_SAT'),item)"
                                                >
                                                    <!-- {{  itemtd }} -->

                                                    <span v-if="item[itemtd.value+'_FLAG'] != null" class="red--text font-weight-bold">
                                                        {{ item[itemtd.value+'_FLAG'] }}
                                                    </span>

                                                    <span v-else>
                                                        
                                                        <span v-if="item[itemtd.value.replace('TEMP','TEMPERATURE').replace('DOCON','DO_CON').replace('DOSAT','DO_SAT')] == null">
                                                            NA
                                                        </span>
                                                        <span v-if="itemtd.value == 'DATETIME_UI'">
                                                            {{ useConvertDisplayDatetime(item[itemtd.value])}}
                                                          </span>
                                                        <span v-else>
                                                            

                                                            {{ item[itemtd.value.replace('TEMP','TEMPERATURE').replace('DOCON','DO_CON').replace('DOSAT','DO_SAT')] }}
                                                            
                                                        </span>

                                                    </span>
                                                </td>
                                            </tr>

                                        </tbody>
                                        </template>
                                    
                                    </v-data-table>

                                </v-col>
                            </v-row>

                            <v-row class="pt-4">
                                <v-spacer></v-spacer>
                                <v-btn
                                color="button"
                                class="mx-2 white--text"
                                :disabled="tbody_report.length == 0 || tbody_report_selected.length == 0"
                                @click="cancelSelected()"
                                >
                                    CANCEL
                                </v-btn>
                                <v-btn
                                color="button"
                                class="mx-2 white--text"
                                :disabled="tbody_report.length == 0 || tbody_report_selected.length == 0"
                                @click="view_report = 'selected'"
                                >
                                    SUBMIT
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-row>

                        </v-col>
                    </v-row>
                    
                    <!-- View Selected Data Reading before Submit -->
                    <v-row v-show="view_report == 'selected'">
                        <v-col>

                            <v-row>
                                <v-col class="pa-1">
                                    <v-data-table
                                    :headers="thead_report_selected"
                                    :items="tbody_report_selected"
                                    :header-props="{ sortIcon: null }"
                                    class="elevation-1 mt-10 headerDtSarawak"
                                    :class="{'datatable-mobile' : !$vuetify.breakpoint.lgAndUp}"
                                    :items-per-page="10"
                                    no-select-on-click
                                    :mobile-breakpoint="0"
                                    ></v-data-table>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="pa-1 pt-5">
                                    <v-textarea
                                    outlined
                                    label="Remarks"
                                    placeholder="Remarks"
                                    rows="3"
                                    v-model="remarksDataToValidate"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row class="pt-4">
                                <v-spacer></v-spacer>
                                <v-btn
                                color="button"
                                dark
                                class="mx-2"
                                @click="view_report = 'main';remarksDataToValidate = ''"
                                >
                                    CANCEL
                                </v-btn>
                                <v-btn
                                color="button"
                                dark
                                class="mx-2"
                                @click="sendSelectedDataToValidateApproval()"
                                >
                                    SUBMIT
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-row>

                        </v-col>
                    </v-row>

                </v-tab-item>

                <!-- Content Tab Validation Pending -->
                <v-tab-item :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

                    <!-- Title -->
                    <v-row v-show="tbody_report.length != 0">
                        <v-col class="pb-0 mt-5">
                            <v-subheader 
                            class="d-flex justify-center align-center black--text font-weight-bold" 
                            style="text-transform:uppercase"
                            >
                                {{titlePending}}
                            </v-subheader>
                        </v-col>
                    </v-row>

                    <!-- Table Pending -->
                    <v-row>
                        <v-col>

                            <!-- Button Export Table -->
                            <div class="section-btn-export">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="excel"
                                    elevation="2"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="exportReport()"
                                    class="btn-export-table btn-excel mb-2"
                                    >
                                        <v-icon
                                        color="white"
                                        center
                                        dark
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </v-btn>
                                    </template>
                                    <span>Export to Excel</span>
                                </v-tooltip>
                            </div>

                            <!-- Table Pending-->
                            <div>
                                <v-data-table
                                :headers="thead_pending"
                                :items="tbody_pending"
                                :header-props="{ sortIcon: null }"
                                class="elevation-1 headerDtSarawak tablePending"
                                :class="{'datatable-mobile' : !$vuetify.breakpoint.lgAndUp}"
                                :items-per-page="5"
                                no-select-on-click
                                fixed-header
                                :mobile-breakpoint="0"
                                :loading="loadingTable_report"
                                loading-text="Data Loading... Please wait"
                                >
                                
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-row>
                                            <v-col>
                                                <v-select
                                                :items="['Approve','Reject']"
                                                v-model="item.action"
                                                solo 
                                                dense
                                                hide-details
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </template>
                                
                                    <template v-slot:[`item.approverremarks`]="{ item }">
                                        <v-row>
                                            <v-col>
                                                <v-textarea
                                                outlined
                                                label="Remarks"
                                                placeholder="Remarks"
                                                rows="2"
                                                full-width
                                                hide-details
                                                v-model="item.approverremarks"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </template>
                                
                                    <template v-slot:[`item.modelCheckbox`]="{ item }">
                                        <v-row>
                                            <v-col class="d-flex justify-center align-center">
                                                <v-checkbox
                                                v-model="item.modelCheckbox"
                                                :disabled="item.action == '' || item.approverremarks == ''"
                                                @change="detectCheckbox()"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </template>

                                </v-data-table>
                            </div>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex justify-center align-center">
                            <v-btn
                            color="button"
                            class="mx-2 white--text"
                            :disabled="tbody_pending.length == 0 || arrayCollectChecked.length == 0"
                            >
                                CANCEL
                            </v-btn>
                            <v-btn
                            color="button"
                            class="mx-2 white--text"
                            :loading="loadingSubmit_pending"
                            :disabled="tbody_pending.length == 0 || arrayCollectChecked.length == 0"
                            @click="sendSelectedCheckboxForApproval()"
                            >
                                SUBMIT
                            </v-btn>
                        </v-col>
                    </v-row>
                    

                    

                    
                </v-tab-item>

                <!-- Content Tab Validation History -->
                <v-tab-item :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

                    <!-- Title -->
                    <v-row v-show="tbody_report.length != 0">
                        <v-col class="pb-0 mt-5">
                            <v-subheader 
                            class="d-flex justify-center align-center black--text font-weight-bold" 
                            style="text-transform:uppercase"
                            >
                                {{titleHistory}}
                            </v-subheader>
                        </v-col>
                    </v-row>


                    <!-- Table History -->
                    <v-row>
                        <v-col>

                            <!-- Button Export Table -->
                            <div class="section-btn-export">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    color="excel"
                                    elevation="2"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="btn-export-table btn-excel mb-2"
                                    >
                                        <v-icon
                                        color="white"
                                        center
                                        dark
                                        >
                                            mdi-microsoft-excel
                                        </v-icon>
                                    </v-btn>
                                    </template>
                                    <span>Export to Excel</span>
                                </v-tooltip>
                            </div>

                            <!-- Table History-->
                            <div>
                                <v-data-table
                                :headers="thead_history"
                                :items="tbody_history"
                                :header-props="{ sortIcon: null }"
                                class="elevation-1 headerDtSarawak"
                                :class="{'datatable-mobile' : !$vuetify.breakpoint.lgAndUp}"
                                :items-per-page="5"
                                no-select-on-click
                                fixed-header
                                :mobile-breakpoint="0"
                                :loading="loadingTable_report"
                                loading-text="Data Loading... Please wait"
                                ></v-data-table>
                            </div>

                        </v-col>
                    </v-row>
                    
                </v-tab-item>

            </v-tabs-items>

            

        </v-container>
        

        <!-- Footer Legend -->
        <template >
            <v-footer padless :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

            <v-col
            class="text-center footer-report"
            cols="12"
            >
                <span v-show="modeSelected == 'Continuous'" class="one-text-footer mx-5">
                    <span class="red--text">C</span>
                    <span class="blue--text">=CALIBRATION</span>
                </span>
                <span v-show="modeSelected == 'Continuous'" class="one-text-footer mx-5">
                    <span class="red--text">M</span>
                    <span class="blue--text">=MAINTENANCE</span>
                </span>
                <span v-show="modeSelected == 'Continuous'" class="one-text-footer mx-5 dontBreak">
                    <span class="red--text">DOR</span>
                    <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
                </span><br>
                <span v-show="modeSelected == 'Continuous'" class="one-text-footer mx-5">
                    <span class="red--text">OUTL</span>
                    <span class="blue--text">=OUTLIER</span>
                </span>
                <span v-show="modeSelected == 'Continuous'" class="one-text-footer mx-5">
                    <span class="red--text">CONST</span>
                    <span class="blue--text">=CONSTANT READING</span>
                </span>
                <span class="one-text-footer mx-5">
                    <span>NA</span>
                    <span class="blue--text">=DATA NOT AVAILABLE</span>
                </span>
            </v-col>
            </v-footer>
        </template>

    </v-container>


    

</template>

<script>
import { bus } from '@/main';
import axios from 'axios';
import Papa from "papaparse";

export default {
    data: () => ({

        // General
        pathModul: [
            {
                text: 'Home',
                disabled: false,
                // href: '/',
                href: '/Mapviewer',
            },
            {
                text: 'Data Validation',
                disabled: true,
                href: '/Operational/Validation',
            },
        ],

        dataParams: {
            Continuous: [],
            Manual: [],
        },
        


        // Filtering Input
        listMode: ["Continuous","Manual"],
        currentMode: {},
        modeSelected: "",

        dataOriginalListStations: [],
        listStations: [],
        selectedStation: "",
        modelCalendarFrom: false,
        selectedDateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modelCalendarTo: false,
        selectedDateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


        // Tabs
        tabs: [
            {tab: "QAQC Data Validation Report"},
            {tab: "QAQC Data Validation Pending"},
            {tab: "QAQC Data Validation History"}
        ],
        modelTab: null,


        // Report
        view_report: "main",
        titleReport: "",
        loadingTable_report: false,
        thead_report: [],
        tbody_report: [],
        displayPerPage_report: 10,

        thead_report_selected: [
            {text: "Station ID", value: "stationId", divider: true, align: "center", sortable: true },
            {text: "Datetime", value: "datetime", divider: true, align: "center", sortable: true },
            {text: "Parameter", value: "params", divider: true, align: "center", sortable: true },
            {text: "Value", value: "reading", divider: true, align: "center", sortable: true }
        ],
        tbody_report_selected: [],
        remarksDataToValidate: "",


        // Pending
        titlePending: "",
        thead_pending: [
            {text: "Station ID", value: "stationid", divider: true, align: "center", sortable: true },
            {text: "Datetime", value: "datetime_ui", divider: true, align: "center", sortable: true },
            {text: "Parameter", value: "parameter", divider: true, align: "center", sortable: true },
            {text: "Value", value: "value", divider: true, align: "center", sortable: true },
            {text: "Status", value: "status", divider: true, align: "center", sortable: true },
            {text: "Requester Remarks", value: "requesterremarks", divider: true, align: "center", sortable: true },
            {text: "Requested By", value: "requestedby", divider: true, align: "center", sortable: true },
            {text: "Requested Date", value: "requesteddt_ui", divider: true, align: "center", sortable: true },
            {text: "Action", value: "action", divider: true, align: "center", sortable: true },
            {text: "Action By Remarks", value: "approverremarks", divider: true, align: "center", sortable: true },
            {text: "Submission", value: "modelCheckbox", divider: true, align: "center", sortable: true },
        ],
        tbody_pending: [],
        arrayCollectChecked: [],
        loadingSubmit_pending: false,


        // History
        titleHistory: "",
        thead_history: [
            {text: "Station ID", value: "stationid", divider: true, align: "center", sortable: true },
            {text: "Datetime", value: "datetime_ui", divider: true, align: "center", sortable: true },
            {text: "Parameter", value: "parameter", divider: true, align: "center", sortable: true },
            {text: "Value", value: "value", divider: true, align: "center", sortable: true },
            {text: "Status", value: "status", divider: true, align: "center", sortable: true },
            {text: "Requester Remarks", value: "requesterremarks", divider: true, align: "center", sortable: true },
            {text: "Requested By", value: "requestedby", divider: true, align: "center", sortable: true },
            {text: "Requested Date", value: "requesteddt_ui", divider: true, align: "center", sortable: true },
            {text: "Resolve By", value: "approvedby", divider: true, align: "center", sortable: true },
            {text: "Resolve By Remarks", value: "approverremarks", divider: true, align: "center", sortable: true },
            {text: "Resolve Datetime", value: "approveddt_ui", divider: true, align: "center", sortable: true },
        ],
        tbody_history: [],


        // Sementara
        dialogErrorPending: false,


    }),

    mounted(){

        this.getCurrentMode();
        this.getDataParams();
        console.log(this.converttoDBFormat('01/05/2023, 1:45:00 AM'))
    },

    methods: {

        // General

        getCurrentMode(){

            if(localStorage.clickButtonAMIS == undefined){
                this.modeSelected = "Continuous"
            }
            else{

                this.currentMode = JSON.parse(localStorage.clickButtonAMIS)

                // console.log(this.currentMode);

                if(this.currentMode.a == true && this.currentMode.m == false){
                    this.modeSelected = "Continuous"
                }
                else if(this.currentMode.a == false && this.currentMode.m == true){
                    this.modeSelected = "Manual"
                }
                else if(this.currentMode.a == true && this.currentMode.m == true){
                    this.listMode = ["Continuous","Manual"]
                    this.modeSelected = "Continuous"
                }
                else if(this.currentMode.is == true){
                    this.modeSelected = "Investigate Study"
                }

            }

            this.getListStation(this.modeSelected);

        },

        converttoDBFormat(date){

        let tar = date;
        let dt;

        if (tar.includes(" AM")){
            dt = tar.replace(" AM", "_AM")
        } else {
            dt = tar.replace(" PM", "_PM")
        }

        dt = dt.split(", ")
        let tarikh = dt[0];
        let masa = dt[1];



        if (masa.includes("_AM")){
            if (masa.split(":")[0] < 10) {
                masa = "0"+masa.replace("_AM","")
            } else if (masa.split(":")[0] == 12) {
                masa = masa.replace("_AM","").replace("12","00")
            }
            else {
                    masa = masa.replace("_AM","")
            }
        } else {
            masa = masa.replace("_PM","")
            if (masa.split(":")[0] != "12") {
                masa = (parseInt(masa.split(":")[0]) + 12) + ":" + masa.split(":")[1] + ":" + masa.split(":")[2]
            }
        }

        // console.log("masa",masa)

        tarikh = tarikh.split("/")[2] + "-" + tarikh.split("/")[1] + "-" + tarikh.split("/")[0]

        date = tarikh + "T" + masa
        return date;

        // console.log(date)
        },

        getDataParams(){

            axios.all([
                axios.get(this.globalUrl+'mqm2/miqims/params', {
                    headers: {
                        Authorization: "Bearer " + this.$store.getters.user.token,
                    },
                }),
                axios.get(this.globalUrl+'mqm2/miqims/params_manual', {
                    headers: {
                        Authorization: "Bearer " + this.$store.getters.user.token,
                    },
                })
            ])
            .then(axios.spread((paramConti, paramManual) => {

                this.dataParams["Continuous"] = paramConti.data;
                this.dataParams["Manual"] = paramManual.data;

            }))
            .catch((error) => {
                console.log(error);
            });

        },



        // Filtering Input

        getListStation(mode){

            this.dataOriginalListStations = [];
            this.listStations = [];
            this.selectedStation = "";

            let urlDataStations = ""
            if(mode == "Continuous"){
                urlDataStations = "mqm2/miqims/stations"
            }
            else if(mode == "Manual"){
                urlDataStations = "mqm2/miqims/stations_manual"
            }

            axios.get(this.globalUrl+urlDataStations, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                // console.log(data);

                this.dataOriginalListStations = data;
                
                for(let i in data){
                    this.listStations.push(data[i].STATION_ID + " - " + data[i].LOCATION)
                }
                
                
            })
            .catch(error => {
                console.log(error);
            })

        },


        generateAllTable(){

            this.loadingTable_report = true;

            let stationID = this.selectedStation.split(" - ")[0];

            let urlRawData = ""
            let urlDataValidation = ""
            if(this.modeSelected == "Continuous"){
                urlRawData = 'mqm2/miqims/rawdata?startdt='+this.selectedDateFrom+'T00:00:00&enddt='+this.selectedDateTo+'T23:59:59&stationid=all'
                urlDataValidation = "mqm2/maint/dataval?stationid="+stationID+"&startdt="+this.selectedDateFrom+"T00:00:00&&enddt="+this.selectedDateTo+"T23:59:59"
            }
            else if(this.modeSelected == "Manual"){
                urlRawData = 'mqm2/miqims/rawdata_manual?startdt='+this.selectedDateFrom+'T00:00:00&enddt='+this.selectedDateTo+'T23:59:59&stationid=all'
                urlDataValidation = "mqm2/maint/dataval_manual?stationid="+stationID+"&startdt="+this.selectedDateFrom+"T00:00:00&&enddt="+this.selectedDateTo+"T23:59:59"
            }

            axios.all([
                axios.get(this.globalUrl+urlRawData, {
                    headers: {
                        Authorization: "Bearer " + this.$store.getters.user.token,
                    },
                }),
                axios.get(this.globalUrl+urlDataValidation, {
                    headers: {
                        Authorization: "Bearer " + this.$store.getters.user.token,
                    },
                })
            ])
            .then(axios.spread((rawData, valData) => {

                let dataReport = []
                for(let i in rawData.data){
                    if(rawData.data[i].STATION_ID == stationID){
                        dataReport.push(rawData.data[i])
                    }
                }

                // console.log("dr",dataReport)

                this.createTableReport(dataReport);

                let dataPending = [];
                let dataHistory = [];
                for(let i in valData.data){
                    if(valData.data[i].status == "pending"){
                        dataPending.push(valData.data[i])
                    }
                    else{
                        dataHistory.push(valData.data[i])
                    }
                }
                this.createTablePending(dataPending);
                this.createTableHistory(dataHistory);

            }))
            .catch((error) => {
                console.log(error);
                this.loadingTable_report = false;
            });

        },



        // Report
        createTableReport(rawdata){

            this.createTheadTableReport(rawdata);

        },

        createTheadTableReport(rawdata){

            let stationID = this.selectedStation.split(" - ")[0];

            let urlSelectedParams = ""
            if(this.modeSelected == "Continuous"){
                urlSelectedParams = 'mqm2/miqims/params?stationid=' + stationID
            }
            else if(this.modeSelected == "Manual"){
                urlSelectedParams = 'mqm2/miqims/params_manual?stationid=' + stationID
            }

            this.thead_report = [];

            axios.get(this.globalUrl+urlSelectedParams, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                // console.log(data);

                if(this.modeSelected == "Continuous"){
                    for(let i in data){

                        let par_val;

                        par_val = data[i].reading

                        if (data[i].reading == "TEMPERATURE"){
                            par_val = "TEMP"
                        } 
                        if (data[i].reading == "DO_CON"){
                            par_val = "DOCON"
                        } 
                        if (data[i].reading == "DO_SAT"){
                            par_val = "DOSAT"
                        }

                        this.thead_report.push({
                            text: data[i].text.replaceAll("(NULL)",""),
                            value: par_val,
                            divider: true,
                            align: "center",
                            sortable: true,
                        })
                    }
                }
                else{
                    for(let i in data){

                        let par_val;

                        par_val = data[i].reading

                        if (data[i].reading == "TEMPERATURE"){
                            par_val = "TEMP"
                        } 
                        if (data[i].reading == "DO_CON"){
                            par_val = "DOCON"
                        } 
                        if (data[i].reading == "DO_SAT"){
                            par_val = "DOSAT"
                        }

                        if(data[i].text.includes("(Index)") == false){
                            this.thead_report.push({
                                text: data[i].text.replaceAll("(NULL)",""),
                                value: par_val,
                                divider: true,
                                align: "center",
                                sortable: true,
                            })
                        }
                    }
                }

                this.thead_report.unshift({ 
                    text: "Station ID", 
                    value: "STATION_ID", 
                    divider: true, 
                    align: "center", 
                    sortable: true 
                })

                this.thead_report.unshift({ 
                    text: "Datetime", 
                    value: "DATETIME_UI", 
                    divider: true, 
                    align: "center", 
                    sortable: true 
                })

                this.createTbodyTableReport(rawdata)
                
                
            })
            .catch(error => {
                console.log(error);
                this.loadingTable_report = false;
            })

        },

        createTbodyTableReport(rawdata){

            this.titleReport = "";
            this.tbody_report = [];
            this.tbody_report_selected = [];
            this.remarksDataToValidate = ""

            if(rawdata.length != 0){

                // create key yg ada "selected" for every keys data utk buat selected td
                let keysRawData = Object.keys(rawdata[0])
                for(let i in rawdata){
                    for(let x in keysRawData){
                        rawdata[i][keysRawData[x]+"_selected"] = false;
                    }
                }

                for(let i in rawdata){
                    if(rawdata[i].DATETIME != null && rawdata[i].DATETIME != ''){
                        // rawdata[i]["DATETIME_UI"] = this.useConvertDisplayDatetime(rawdata[i].DATETIME);
                        rawdata[i]["DATETIME_UI"] = rawdata[i]["DATETIME"]
                    }
                }

                this.tbody_report = rawdata;
                this.titleReport = "DATA VALIDATION RECORD FOR STATION "+this.selectedStation+" FROM "+this.useConvertDisplayDate(this.selectedDateFrom)+" TO "+this.useConvertDisplayDate(this.selectedDateTo);

                this.loadingTable_report = false;

            }
            else{
                this.loadingTable_report = false;
            }

        },

        setClassTDReport(flag,reading,valThead,data){

            // console.log(data);

            let class_td_report = "";

            let thisIsDataPending = false;
            for(let i in this.tbody_pending){
                if(this.tbody_pending[i].datetime == data.DATETIME &&
                this.tbody_pending[i].parameter == valThead
                && this.tbody_pending[i].stationid == data.STATION_ID ){
                    thisIsDataPending = true
                }
            }

            if(thisIsDataPending == true){
                class_td_report = "font-weight-bold btn-non-click-td text-center white--text td-selected-pending"
            }
            else{
                if(flag != undefined && flag != "" && flag != null){

                    class_td_report = "red--text font-weight-bold btn-non-click-td text-center"

                }
                else{

                    if(reading == null){
                        class_td_report = "btn-non-click-td  text-center"
                    }
                    else{
                        if(valThead != "STATION_ID" && valThead != "DATETIME_UI"){
                            
                            class_td_report = "pointer btn-click-td  text-center"
                            
                        }
                        else{
                            class_td_report = "btn-non-click-td  text-center"
                        }
                    }

                }
            }

            

            return class_td_report

        },

        clickOnTDTableReport(flag,reading,valThead,data){


            let thisIsDataPending = false;
            for(let i in this.tbody_pending){
                if(this.tbody_pending[i].datetime == data.DATETIME &&
                this.tbody_pending[i].parameter == valThead
                && this.tbody_pending[i].stationid == data.STATION_ID ){
                    thisIsDataPending = true
                }
            }

            if(thisIsDataPending == false){

                // set true to key "selected" in rawdata tbody report
                let keysTbody_report = Object.keys(this.tbody_report[0])
                for(let i in this.tbody_report){
                    for(let x in keysTbody_report){
                        if(keysTbody_report[x] == valThead){
                            if(this.tbody_report[i].DATETIME_UI == data.DATETIME_UI &&
                            this.tbody_report[i][keysTbody_report[x]] == reading){
                                if(reading != null){
                                    this.tbody_report[i][keysTbody_report[x]+"_selected"] = true
                                }
                            }
                        }
                    }
                }

                // Process of Collecting Data Selected
                if(flag != undefined && flag != "" && flag != null){

                    console.log("non-click");

                }
                else{

                    if(reading == null){
                        console.log("non-click");
                    }
                    else{
                        if(valThead != "STATION_ID" && valThead != "DATETIME_UI"){

                            console.log("1",data.DATETIME_UI)

                            let dt_ui = this.useConvertDisplayDatetime(data.DATETIME_UI)
                            
                            if(this.tbody_report_selected.length == 0){

                                this.tbody_report_selected.push({
                                    stationId: data.STATION_ID,
                                    datetime: dt_ui,
                                    params: valThead,
                                    reading: reading,
                                })

                            }
                            else{

                                console.log(this.tbody_report_selected[0].datetime)
                                console.log("2",data.DATETIME_UI)

                                let alreadySelected = false
                                for(let i in this.tbody_report_selected){
                                    if(this.tbody_report_selected[i].datetime == dt_ui &&
                                    this.tbody_report_selected[i].params == valThead &&
                                    this.tbody_report_selected[i].reading == reading){
                                        alreadySelected = true;
                                    }
                                }

                                if(alreadySelected == false){
                                    this.tbody_report_selected.push({
                                        stationId: data.STATION_ID,
                                        datetime: dt_ui,
                                        params: valThead,
                                        reading: reading,
                                    })
                                }

                            }

                        }
                        else{
                            console.log("non-click");
                        }
                    }

                }

                console.log(this.tbody_report_selected);

            }


        },

        cancelSelected(){

            let keysTbody_report = Object.keys(this.tbody_report[0])
            for(let i in this.tbody_report){
                for(let x in keysTbody_report){
                    this.tbody_report[i][keysTbody_report[x]+"_selected"] = false
                }
            }

            this.remarksDataToValidate = ""
            this.tbody_report_selected = []

        },

        sendSelectedDataToValidateApproval(){

            // set payload
            let payload = []
            for(let i in this.tbody_report_selected){
                payload.push({
                    stationid: this.tbody_report_selected[i].stationId,
                    datetime: this.converttoDBFormat(this.tbody_report_selected[i].datetime),
                    parameter: this.tbody_report_selected[i].params,
                    value: this.tbody_report_selected[i].reading,
                    requesterremarks: this.remarksDataToValidate,
                })
            }

            let urlValidateData = ""
            if(this.modeSelected == "Continuous"){
                urlValidateData = "mqm2/maint/adddataval"
            }
            else if(this.modeSelected == "Manual"){
                urlValidateData = "mqm2/maint/adddataval_manual"
            }

            console.log(payload);

            axios.post(this.globalUrl+urlValidateData, payload, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                console.log(data);

                bus.$emit("popupMessage",{
                    status: "success",
                    title: "Data Validation",
                    message: "Your request submitted successfully and pending for approval confirmation",
                })
                
                this.view_report = "main";
                this.generateAllTable();

                
            })
            .catch(error => {
                console.log(error.response);

                bus.$emit("popupMessage",{
                    status: "error",
                    title: "Data Validation",
                    message: "Something went wrong. Please try again.",
                })
            })

        },



        // Pending
        createTablePending(data){

            this.arrayCollectChecked = [];

            console.log(data);
            for(let i in data){

                // Display Date in display format
                data[i]["datetime_ui"] = this.useConvertDisplayDatetime(data[i]["datetime"])
                data[i]["requesteddt_ui"] = this.useConvertDisplayDatetime(data[i]["requesteddt"])

                // Create keys for action & model
                data[i]["action"] = "";
                data[i]["approverremarks"] = "";
                data[i]["modelCheckbox"] = false;

            }

            this.titlePending = "DATA VALIDATION PENDING FOR STATION "+this.selectedStation+" FROM "+this.useConvertDisplayDate(this.selectedDateFrom)+" TO "+this.useConvertDisplayDate(this.selectedDateTo)
            this.tbody_pending = data;

        },

        detectCheckbox(){

            // Noted: Function detectCheckbox() ni just guna utk count total row table yg checked (true) semata2
            // ** Tujuan nk control disable button "CANCEL" & "SUBMIT" di Pending Page

            this.arrayCollectChecked = [];
            for(let i in this.tbody_pending){
                if(this.tbody_pending[i].modelCheckbox == true){
                    this.arrayCollectChecked.push(this.tbody_pending[i])
                }
            }
        },

        sendSelectedCheckboxForApproval(){

            this.loadingSubmit_pending = true;

            let payload = [];
            for(let i in this.tbody_pending){
                if(this.tbody_pending[i].modelCheckbox == true){
                    payload.push({
                        stationid: this.tbody_pending[i].stationid,
                        datetime: this.tbody_pending[i].datetime,
                        parameter: this.tbody_pending[i].parameter,
                        action: this.tbody_pending[i].action == "Approve"? "approved" : "rejected",
                        approverremarks: this.tbody_pending[i].approverremarks,
                    })
                }
            }

            let urlValidateApproval = ""
            if(this.modeSelected == "Continuous"){
                urlValidateApproval = "mqm2/maint/editdataval   "
            }
            else if(this.modeSelected == "Manual"){
                urlValidateApproval = "mqm2/maint/editdataval_manual"
            }

            console.log(payload);

            axios.put(this.globalUrl+urlValidateApproval, payload, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                console.log(data);

                bus.$emit("popupMessage",{
                    status: "success",
                    title: "Validation Approval",
                    message: "Approval submitted successfully",
                })
                
                this.view_report = "main";
                this.generateAllTable();

                this.loadingSubmit_pending = false;

                
            })
            .catch(error => {
                console.log(error.response);

                bus.$emit("popupMessage",{
                    status: "error",
                    title: "Validation Approval",
                    message: "Something went wrong. Please try again.",
                })

                this.loadingSubmit_pending = false;
            })

        },



        // History
        createTableHistory(data){

            console.log(data);
            for(let i in data){

                // Display Date in display format
                data[i]["datetime_ui"] = this.useConvertDisplayDatetime(data[i]["datetime"])
                data[i]["requesteddt_ui"] = this.useConvertDisplayDatetime(data[i]["requesteddt"])
                data[i]["approveddt_ui"] = this.useConvertDisplayDatetime(data[i]["approveddt"])

                data[i]["status"] = data[i]["status"] == "approved"? "Approved" : "Rejected"

            }

            this.titleHistory = "DATA VALIDATION HISTORY FOR STATION "+this.selectedStation+" FROM "+this.useConvertDisplayDate(this.selectedDateFrom)+" TO "+this.useConvertDisplayDate(this.selectedDateTo)
            this.tbody_history = data;

        }

        

    },

    computed: {
        
    },
}
</script>

<style lang="scss">

    @import '~scss/main';

    /* Section Path File/Modul */
    .pathModul{
        padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a,
    .pathModul > li:nth-child(3) > div{
        color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
        padding: 0px;
    }

    /* Section Generate Result Datatable Vue */
    // .container-result-generated{
    //     height: calc(100vh - 265px);
    // }

    .subTitle{
        text-align: center !important;
        background: white;
        // color: #1976D2 !important;
        display: none;
        padding: 15px;
    }

    .subTitle_ {
        text-align: center !important;
        background: white;
        // color: #1976D2 !important;
        display: block;
        padding: 15px;
    }

    .tableValidationReport,
    .tableValidationPending,
    .tableValidationHistory{
        margin: 20px 0px;
    }

    .form-datatable{
        padding: 10px 0px 0px 0px;
    }

    // .tableValidationReport > div:nth-child(1) > table > thead > tr > th,
    // .tableValidationPending > div:nth-child(1) > table > thead > tr > th,
    // .tableValidationHistory > div:nth-child(1) > table > thead > tr > th{
    //     padding: 10px 16px;
    // }

    .tableValidationPending > div:nth-child(1) > table > thead > tr > th:nth-child(10){
        white-space: nowrap !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(1){
      white-space: nowrap !important;
    }

    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td:nth-child(2),
    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td:nth-child(8),
    .tableValidationHistory > div:nth-child(1) > table > tbody > tr > td:nth-child(2){
      white-space: nowrap !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td{
      padding: 0px !important;
    }

    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(1),
    .tableValidationReport > div:nth-child(1) > table > tbody > tr > td:nth-child(2){
      padding: 0px 16px !important;
    }

    .tableValidationPending > div:nth-child(1) > table > tbody > tr > td{
      padding: 16px;
    }

    .action-chips > div:nth-child(1) > div:nth-child(1) > .v-select__slot > .v-select__selections > span{
        background: white !important;
    }

    .btn-after-table{
        margin: 0px 10px;
    }

    .tableValidationReport > .v-data-table__wrapper, 
    .tableValidationPending > .v-data-table__wrapper, 
    .tableValidationHistory > .v-data-table__wrapper {
        overflow-x: auto !important;
        overflow-y: auto !important;
        // max-height: 408px;
    }

    .v-data-table__empty-wrapper > td{
      text-align: left !important;
    }

    .section-btn-export{
        text-align: right;
    }

    .btn-export-table{
        margin-top: 12px;
    }

    .btn-click-td{
        background: white !important;
        height: inherit !important;
        width: -webkit-fill-available !important;
        padding: 0px !important;
    }

    /* .btn-click-td:hover{
        background: #0081BA !important;
        color: white;
    } */

    .btn-non-click-td > span{
        background-color: white !important;
        height: inherit !important;
        width: -webkit-fill-available !important;
        display: block;
        padding: 11px;
        font-size: 0.95em;
        white-space: nowrap;
    }

    .btn-click-td > span{
        background: white;
        height: inherit !important;
        width: -webkit-fill-available !important;
        display: block;
        padding: 11px;
        font-size: 0.95em;
        cursor: pointer;
    }

    .btn-click-td > span:hover{
        background: #04c5d6 !important;
        color: white;
    }

    .btn-click-td > .pending{
        background: #02b2c3 !important;
        color: white;
    }

    .headerValidation > .v-data-table__wrapper > table > tbody > tr:hover{
        background-color: transparent !important;
    }

    /* .btn-click-td.selectBtnClick{
        background: black !important;
    } */
    
    .tablePending > .v-data-table__wrapper > table > thead > tr > th:nth-child(10){
        white-space: nowrap;
    }

    /* Special Section For Validation Report */

    /* contain 2nd table */
    #pageLepasSubmitSelectedTD, #container_btnDpn_valReport{
        display: none;
    }

    /* Footer Report */
    .footer-report{
      background: white;
    }
    
    .btn-click-td > span{
        background: white;
    }
   
    .myClass {
        background: #0199be
      }


    .td-selected{
        background: #04c5d6 !important;
    }

    .td-selected-pending,
    .td-selected-pending > span,
    .td-selected-pending > span > span,
    .td-selected-pending > span > span > span,
    .td-selected-pending > span > span > span > span{
        background: #016f79 !important;
    }

</style>